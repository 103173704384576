import { base64encode, utf16to8, utf8to16, base64decode } from '../../../utils/base64';
import { ypURL, ypURL2 } from '../../../request';

export default {
    name: 'Home',

    data() {
        return {
            defaultIcon: require('./images/nav-zhaiquan.png'),
            list: [],
            sysIconMap: '',
            sysName: '',
            showYP: true,
        };
    },

    beforeMount() {
        const list = this.userInfo.empInfoList
            .filter(it => it.system != 'CEN')
            .map(it => ({
                ...it,
                icon: it.icon ? this.$baseURL + '/' + it.icon : this.defaultIcon,
            }));
        if (this.userInfo.id == 961 || this.userInfo.id == 964) {
            //白菜让 lina 和 18210936707 这两个账号 登录后不显示Forestfile云盘。其它账号才显示 2024-12-10
            this.showYP = false;
        }
        this.list = list;
    },
    created() {
        this.sysIconMap = JSON.parse(localStorage.getItem('sysIconMap'));
        if (this.sysIconMap) {
            if (this.sysIconMap.name) {
                this.sysName = this.sysIconMap.name;
            } else {
                this.sysName = '中心平台';
            }
        }
    },

    methods: {
        closeCurrentWindow() {
            window.opener = null;
            window.close();
            //     window.open("about:blank", "_self");
            //     window.close();
        },
        open() {
            let { href } = this.$router.resolve({
                path: 'home',
                query: {
                    repayId: 1,
                },
            });
            window.open(href, '_blank');
        },
        async handleNavClick(item) {
            // console.log(item);
            const loading = this.$loading();
            // 本地测试用

            if (this.userInfo.name == '追忆测试号') {
                let _url = item.url;
                if (item.system == 'MON') {
                    //债券系统
                    _url = ' http://192.168.110.150:8030';
                }
                if (item.system == 'AYM') {
                    //移民CRM
                    _url = 'http://192.168.110.136:9000/loadLogin/login';
                }
                await this.openSystemURL({
                    url: _url,
                    system: item.system,
                });
            } else {
                // 打包用
                await this.openSystemURL({
                    // 生产（打包）
                    url: item.url,
                    system: item.system,
                });
            }

            // 打包用
            await this.openSystemURL({
                // 生产（打包）
                url: item.url,
                system: item.system,
            });

            loading.close();
        },

        // 跳云盘
        async handleNavClickYP() {
            const loading = this.$loading();
            let url = '';
            let params = '';
            if (this.userInfo.isCloudFile == 1) {
                // 已经绑定云盘账号  带账号密码去登录
                url = {
                    username: this.userInfo.cloudAccount,
                    password: this.userInfo.cloudPassword,
                };
                let loginYPRL = ypURL + '?json=';
                params = base64encode(encodeURIComponent(JSON.stringify(url)));
                window.open(loginYPRL + params);
            } else {
                // 未绑定云盘账号  去未登录页面
                let otherYPRL = ypURL2 + '?json=';
                url = {
                    // domainName: "http://112.74.129.135:8080/center/#/", //测试
                    // secretKey: 'B93384FC34F64A0C8CA8D5F986FEFF6B', //测试

                    domainName: 'center.jindingaus.com', //正式
                    secretKey: '4676CE1B6D9E43E89701515B45430931', //正式

                    accountId: this.userInfo.id,
                    empName: this.userInfo.account,
                };
                params = base64encode(encodeURIComponent(JSON.stringify(url)));
                window.open(otherYPRL + params);
            }
            loading.close();
        },
    },
};
